import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/BlogLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`2020: Launched Goal Contracts. A simple way to commuting to goals. Featured in Product Hunt daily top 5
`}<a parentName="p" {...{
        "href": "https://www.producthunt.com/posts/goal-contracts"
      }}>{`Goal Contracts - A simple and effective way to keep your goals | Product Hunt`}</a></p>
    <p>{`2019: Won Node Knockout again
`}<a parentName="p" {...{
        "href": "https://twitter.com/wmdmark/status/1199071318320328704"
      }}>{`Mark Johnson on Twitter: “Finished my 5th @nodeknockout yesterday with WeatherCooler: chat with people in the same weather as you.Demo: https://t.co/PwaWtZYsBZVote here if you feel so inclined! 👉 https://t.co/ygDGmORcz3Built in ~14hrs with React Native and @expo. Works on iOS, Android and the Web!”`}</a></p>
    <p>{`Featured as the cover story for UBJ`}</p>
    <p>{`2018: Featured in UBJ for project TiltMaps
`}<a parentName="p" {...{
        "href": "https://instagram.com/p/Bh9tRFzAoWG/"
      }}>{`Mark Johnson on Instagram: “According to my 5-year-old, I “look confused” in this photo. He’s not wrong. Either way, it was nice of the folks @upstatebiz to cover my…”`}</a></p>
    <p>{`2018: Featured on Unco podcast
`}<a parentName="p" {...{
        "href": "https://unco.fm/unco/016"
      }}>{`A Bit of a Privacy Minefield with Paul and Mark Johnson — UNCO Podcast`}</a></p>
    <p>{`2018: Interviewed on “The Strong Web”, a podcast about development and health
`}<a parentName="p" {...{
        "href": "https://thestrongweb.simplecast.com/episodes/mark-johnson-cto-of-pathwright-developer-4cf6554a"
      }}>{`https://thestrongweb.simplecast.com/episodes/mark-johnson-cto-of-pathwright-developer-4cf6554a`}</a></p>
    <p>{`2018: Wrote two semi-viral blog post on Medium about side projects and web technology
`}<a parentName="p" {...{
        "href": "https://medium.com/@wmdmark/how-i-shipped-six-side-projects-in-2017-3dde6c77adbb"
      }}>{`How I Launched Six Side Projects in 2017 - Mark Johnson - Medium`}</a>{`
`}<a parentName="p" {...{
        "href": "https://medium.com/@wmdmark/how-graphql-replaces-redux-3fff8289221d"
      }}>{`How GraphQL Replaces Redux - Mark Johnson - Medium`}</a></p>
    <p>{`2017: Launched TiltMaps, a web application that lets you create and order map poster of anywhere on earth. Featured on Product Hunt.
`}<a parentName="p" {...{
        "href": "https://twitter.com/wmdmark/status/938753950995632128"
      }}>{`Mark Johnson on Twitter: “🚀 After six months of nights and weekends, my side project TiltMaps is now live on @producthunt: Create and order beautiful map posters of your favorite places and memories. 👉 https://t.co/GdBm2p00E6… https://t.co/NBdH4BaZHm”`}</a></p>
    <p>{`2017: One the “best overall“ for Node Knockout
`}<a parentName="p" {...{
        "href": "https://twitter.com/wmdmark/status/932670244568608768"
      }}>{`Mark Johnson on Twitter: “And “The 💯 Team” wins the Node Knockout with best overall score!https://t.co/jrZzekkahYgg @aerykpayne @raiderrobert @jhartist”`}</a></p>
    <p>{`2017: Co-founded local JavaScript Meetup group GVL.js
`}<a parentName="p" {...{
        "href": "https://www.meetup.com/GVL-js/events/242999765/"
      }}>{`Inaugural ReactGVL Meetup! | Meetup`}</a></p>
    <p>{`2017: Keynote co-speaker at Grok, a conference for web designers and developers
`}<a parentName="p" {...{
        "href": "https://www.instagram.com/p/BZ6TLYLHCao/?igshid=1pr6d34s63eyd"
      }}>{`Mark Johnson on Instagram: “Doing a keynote at #grok17 so time for a #twinselfie”`}</a></p>
    <p>{`2017: Featured on cover of UBJ for “10 startups to watch”
`}<a parentName="p" {...{
        "href": "https://issuu.com/cjdesigns/docs/0224ubj.web"
      }}>{`https://issuu.com/cjdesigns/docs/0224ubj.web`}</a></p>
    <p>{`2016: Won the “design” category for the Node Knockout, an international coding contest.
`}<a parentName="p" {...{
        "href": "https://twitter.com/rrhoover/status/799656194172403712"
      }}>{`Ryan Hoover on Twitter: “This video site was built in 48 hours. Impressive.Watch Party: https://t.co/Z0F3lmgcQ0 🎉… “`}</a>{`
`}<a parentName="p" {...{
        "href": "https://twitter.com/wmdmark/status/800767126785036288"
      }}>{`Mark Johnson on Twitter: “Proud of our team for winning design in this year’s @nodeknockout! https://t.co/jrZzek2yToAlso @jhartist won solo! Greenville represent!”`}</a></p>
    <p>{`2016: Launched Pathwright 2. Our launch was the #1 on Product Hunt, the largest media site for app launches.
`}<a parentName="p" {...{
        "href": "https://twitter.com/rrhoover/status/794169983014084608"
      }}>{`Ryan Hoover on Twitter: “Best landing page 😻… ”`}</a></p>
    <p>{`2013-2015: Mentor at The Iron Yard, a globally recognized code school and startup incubator.
`}<a parentName="p" {...{
        "href": "https://twitter.com/wmdmark/status/316203743035133953"
      }}>{`Mark Johnson on Twitter: “Excited and honored to be a class mentor for @MarcoSuarez’s Digital Product Design class @TheIronYard: http://t.co/z8Z00pPX1Y”`}</a>{`
`}<a parentName="p" {...{
        "href": "https://twitter.com/wmdmark/status/584017625925165057"
      }}>{`Mark Johnson on Twitter: “I’m proud to be a mentor @IronYardVenture accelerator program and applications are open for their next cohort: http://t.co/oqSz8HC2MW”`}</a></p>
    <p>{`2013: Featured in Update Business Journal
`}<a parentName="p" {...{
        "href": "https://upstatebusinessjournal.com/pathwright-tackles-the-course-of-education/"
      }}>{`Pathwright Tackles the Course of Education - Upstate Business Journal`}</a></p>
    <p>{`2012: Successfully funded and launched “The Lamp Post Guild”, an online art school, on Kickstarter
`}<a parentName="p" {...{
        "href": "https://www.kickstarter.com/projects/pathwrightpress/the-lamp-post-guild/description"
      }}>{`https://www.kickstarter.com/projects/pathwrightpress/the-lamp-post-guild/description`}</a></p>
    <p>{`2012: Founded Pathwright, an online learning management platform; Featured in major press publications such as TechCrunch.
`}<a parentName="p" {...{
        "href": "https://techcrunch.com/2012/05/08/pathwright-launch/"
      }}>{`Pathwright Launches Platform To Let Anyone Create, Sell Branded Online Courses – TechCrunch`}</a></p>
    <p>{`2009-2012: Founder and Developer at DUO Interactive. Developed large websites and web-based applications for major national and regional clients
2006-2009: Lead Interactive Designer at Portland Studios. Designed and developed interactive games and websites for clients; designed and developed Flex, a content management system; designed and developed NoiseTrade, a platform for delivery of free music downloads`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      